import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import Seo from "@atoms/Seo"
import Hero from "@blocks/index/HeroMovie"
import Content from "@blocks/index/Content"
import News from "@blocks/index/News"
import css from "@css/layouts/l-index.module.styl"

export default function Index()
{
	return(
		<>
		<Seo/>
		<div className={css.container}>
			<Hero/>
			<Content/>
			<News/>
		</div>
		</>
	)
}