import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { globalHistory } from '@reach/router'
// import { useBreadcrumb } from 'gatsby-plugin-breadcrumb'
// import { TransitionLink } from "@atoms/Link"
// import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/p-scroll-gide.module.styl"

function ScrollGide()
{
    
    return (
        <div className={css.pScrollGide}>
            <div className={css.pScrollGide__line}></div>
            <p className={css.pScrollGide__text}>Scroll</p>
        </div>
    )
}

export { ScrollGide }