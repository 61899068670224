import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import * as Anim from "@atoms/animation/Trigger"
import css from "@css/projects/index/p-content.module.styl"

export default function Content()
{
    const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "indexContent__img__main01@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            main02 : file(relativePath: { eq: "indexContent__img__main02@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            main03 : file(relativePath: { eq: "indexContent__img__main03@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    const sectionData =  [
        {
            type: 'right',
            image: q.main01,
            label: `Information`,
            title: `地域発展と未来へのまちづくりを目指した組織づくり。`,
            text: `私たちは、各事業ごとに違う切り口から「地域発展と未来へのまちづくり」を目指しています。ここでは各事業の情報を掲載しています。`,
            button: {
                label: `企業情報`,
                to: `/info`
            }
        },
        {
            type: 'left',
            image: q.main02,
            label: `CSR活動`,
            title: `人、自然、文化、産業が輝く「共生のまち」を目指して。`,
            text: `さまざまな活動をとうして社会貢献を目指しています。ここでは、CSR活動やその他活動についてご紹介します。`,
            button: {
                label: `取り組み`,
                to: `/csr`
            }
        },
        {
            type: 'right',
            image: q.main03,
            label: `Recruit`,
            title: `採用情報`,
            text: `求める人物像、採用情報を掲載しています。`,
            button: {
                label: `採用情報`,
                to: `/recruit`
            }
        }
    ]
    return(
        <section className={css.container}>
            <div className={css.inner}>
                { sectionData.map(( {type, image, label, title, text, button}, i)=>{
                    return(
                        <section key={i} className={`${type === "right" ? css.sectionRight : css.sectionLeft }`}>
                            <Anim.Trigger className={css.imageWrapper}>
                                <figure className={css.image}>
                                    <BackgroundFluidImage data={image}>
                                    </BackgroundFluidImage>
                                </figure>
                            </Anim.Trigger>
                            <div className={css.content}>
                                <Anim.Trigger>
                                    <div className={css.label}>{label}</div>
                                </Anim.Trigger>
                                <Anim.Trigger>
                                    <h2 className={css.title} dangerouslySetInnerHTML={{ __html: title}}></h2>
                                </Anim.Trigger>
                                <Anim.Trigger>
                                    <p className={css.text}>{text}</p>
                                </Anim.Trigger>
                                <Anim.Trigger>
                                    <div className={css.links}>
                                        <TransitionLink className={css.button} to={button.to}>{button.label}</TransitionLink>
                                    </div>
                                </Anim.Trigger>
                            </div>
                        </section>
                    )
                })}
        
            </div>
        </section>
    )
}
