import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import NewsList from "@blocks/page/NewsList"
import css from "@css/projects/index/p-news.module.styl"
import * as Anim from "@atoms/animation/Trigger"

export default function News()
{
    const q = useStaticQuery(graphql`
        query {
            newsData: allMicrocmsNews(
                limit: 5
                sort: { fields: date, order: DESC }
            ) {
                edges {
                    node {
                        content
                        date(formatString: "YYYY.MM.DD")
                        title
                        slug
                        newsId
                        is_private
                    }
                }
            }
        }
    `)

    return(
        <section className={css.container}>
            <div className={css.inner}>
                <Anim.Trigger>
                    <p className={css.nLabel}>News</p>
                </Anim.Trigger>
                <Anim.Trigger>
                    <h3 className={css.nTitle}>お知らせ</h3>
                </Anim.Trigger>
                <NewsList data={q.newsData.edges} animation/>
                <Anim.Trigger>
                <div className={css.links}>
                    <TransitionLink className={css.button} to="/news">もっと見る</TransitionLink>
                </div>
                </Anim.Trigger>
        
            </div>
        </section>
    )
}
