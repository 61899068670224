import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import * as Anim from "@atoms/animation/Animation"
import { ScrollGide } from '@projects/ScrollGide'
import { YoutubeBackground } from '@projects/VideoBackground'
import css from "@css/projects/index/p-hero.module.styl"

export default function Footer()
{
    const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "indexHero__img__main01@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return(

        <div className={css.container} id="hero">
            <div className={css.bg}>
                <YoutubeBackground videoId="AoQ_D2kt7sQ" />
            </div>
            <ScrollGide />
            <div className={css.inner}>
                <Anim.ToggleClass delay={3.3} type="FadeBox">
                    <h1 className={css.copy1}>
                        <Anim.ToggleClass delay={3.3} type="Fragment">
                            <Anim.Item>
                                <span>DRAW</span>
                                <span></span>
                            </Anim.Item>
                            <br />
                            <Anim.Item>
                                <span>THE FUTURE</span>
                                <span></span>
                            </Anim.Item>
                        </Anim.ToggleClass>
                    </h1>
                </Anim.ToggleClass>
                <Anim.ToggleClass delay={3.6} type="FadeBox">
                    <h2 className={`${css.copy2} anim-fade-box-copy2`}>
                        <Anim.Item>
                            <span>地域の未来をつくる。</span>
                            <span></span>
                        </Anim.Item>
                    </h2>
                </Anim.ToggleClass>
                <Anim.ToggleClass delay={4.6} type="Fade">
                    <div className={css.links}>
                        <TransitionLink className={css.button} to="/about">大泉グループとは</TransitionLink>
                    </div>
                </Anim.ToggleClass>
            </div>
        </div>
    )
}
